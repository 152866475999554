import { useQuery, useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';
import { addMultiOwnersToAnnouncements } from 'api/announcements/announcements';
import { MultiTreeSelectGroupTypeEnum } from 'ui/organizationsTreeSelect/treeMultiSelect/groupedTreeMultiSelectBody/GroupedTreeMultiSelectBody.enum';
import { fetchOwners } from 'api/owners/owners';
import { OwnerRegistrationNumberSortEnum, OwnersQueryKeysEnum } from 'context/query/queryContext/QueryContext.enum';

import { AddMultiOwners } from './AddMultiOwners';
import { AddMultiOwnersContainerProps, AddMultiOwnersFormBody } from './AddMultiOwners.types';
import { useAddMultiOwnersValidation } from './AddMultiOwners.validation';

export const AddMultiOwnersContainer = ({
  checkedAnnouncements,
  setCheckedAnnouncements,
}: AddMultiOwnersContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const [selectedOwnersGroup, setSelectedOwnersGroup] = useState<MultiTreeSelectGroupTypeEnum>(
    MultiTreeSelectGroupTypeEnum.EXTRA,
  );
  const { schema } = useAddMultiOwnersValidation();

  const methods = useForm<AddMultiOwnersFormBody>({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const { data: ownersResponse } = useQuery([QueryKeyEnum.OWNERS_SEARCH], fetchOwners, {
    args: {
      offset: 0,
      limit: 100,
      sort: [OwnerRegistrationNumberSortEnum.ASC],
      [OwnersQueryKeysEnum.ARCHIWALNY]: false,
    },
  });

  const { mutate: onAddMultiOwnersToAnnouncements, isLoading } = useMutation(addMultiOwnersToAnnouncements, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.ANNOUNCEMENTS]);
      toast.dark(formatMessage({ id: AppMessages['announcements.multiAction.addOwners.successMessage'] }));
      setCheckedAnnouncements([]);
      onModalClose();

      setTimeout(() => {
        queryClient.invalidateQueries([QueryKeyEnum.ANNOUNCEMENTS]);
      }, 5000);
    },
  });

  const onSubmit = ({ ids }: AddMultiOwnersFormBody) => {
    const selectedKey = selectedOwnersGroup === MultiTreeSelectGroupTypeEnum.EXTRA ? 'idPkds' : 'idKomorek';

    onAddMultiOwnersToAnnouncements({
      idKomunikatow: checkedAnnouncements.map(({ id }) => id),
      idKomorek: [],
      idPkds: [],
      [selectedKey]: typeof ids === 'string' ? [ids] : ids?.map(({ id }) => id) || [],
    });
  };

  useEffect(() => {
    methods.reset();
  }, [isModalOpen]);

  return (
    <FormProvider {...methods}>
      <AddMultiOwners
        checkedAnnouncements={checkedAnnouncements}
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
        isLoading={isLoading}
        onSubmit={onSubmit}
        setSelectedOwnersGroup={setSelectedOwnersGroup}
        owners={ownersResponse?.data || []}
      />
    </FormProvider>
  );
};
