import { Controller, useFormContext } from 'react-hook-form';
import { Modal, Button, Select, Input, AutocompleteOption, Option } from 'shared-ui';
import { useEffect } from 'react';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';
import { Form } from 'ui/form/Form';
import { FormAutocompleteContainer } from 'reusable/formAutocomplete/FormAutocompleteContainer';
import { QueryKeyEnum } from 'core/global.enum';
import { fetchTerminals } from 'api/terminals/terminals';
import { AnnouncementModDescriptionEnum } from 'api/announcements/announcements.enum';
import { MultiAutocomplete } from 'ui/multiAutocomplete/MultiAutocomplete';
import { useRenderMultiAutocompleteOption } from 'ui/multiAutocomplete/hooks/useRenderMultiAutocompleteOption';
import { announcementKeywords } from '../AnnouncementsList.types';
import { KeywordsInput } from 'ui/keywordsInput/KeywordsInput';
import { OrganizationsTreeSelect } from 'ui/organizationsTreeSelect/OrganizationsTreeSelect';
import { GroupTerminalsPermissionsIdSortEnum } from 'context/query/queryContext/QueryContext.enum';

import { AddAnnouncementProps, AddAnnouncementFormBody } from './AddAnnouncement.types';
import * as Styled from './AddAnnouncement.styles';

export const AddAnnouncement = ({
  onModalOpen,
  onModalClose,
  isLoading,
  onSubmit,
  isModalOpen,
  selectedServerId,
  setSelectedServerId,
  servers,
  mods,
  modes,
  setSelectedOwnersGroup,
  owners,
  isLoadingMods,
}: AddAnnouncementProps) => {
  const { formatMessage } = useLocale();
  const { renderMultiAutocompleteOption } = useRenderMultiAutocompleteOption(undefined, true);
  const { yesNoOptions } = useAppSelectOptions();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch,
    setValue,
    setError,
    getFieldState,
    clearErrors,
  } = useFormContext<AddAnnouncementFormBody>();

  const selectedTkd = watch('tkd');
  const isTkdNotSelected = !selectedTkd;
  const tkdErrorMessage = getFieldState('tkd').error?.message;
  const selectedMod = watch('mod');
  const isModNotSelected = !selectedMod;
  const selectedMode = watch('tryb');
  const isModeNotSelected = !selectedMode?.length;

  const { serverOptions } = useAppSelectOptions({ servers });
  const modFilterOptions = mods.map((mod) => ({
    label: formatMessage({ id: AnnouncementModDescriptionEnum[mod] }),
    value: mod,
  }));
  const trybOptions = modes.map((mode) => ({ label: `${mode}`, value: mode }));
  const ownersOptions = owners.map((owner) => ({
    id: owner.id,
    nazwa: `${owner.imie} ${owner.nazwisko}`,
    kod: owner.nrEwidencyjny,
  }));

  useEffect(() => {
    setValue('mod', null);
  }, [selectedTkd]);

  useEffect(() => {
    setValue('tryb', []);
  }, [selectedMod]);

  useEffect(() => {
    setValue('potwierdzenie', null);
  }, [selectedMode]);

  return (
    <>
      <Button onClick={onModalOpen} variant="contained">
        {formatMessage({ id: AppMessages['announcement.add.title'] })}
      </Button>

      <Modal
        header={formatMessage({ id: AppMessages['announcement.add.title'] })}
        open={isModalOpen}
        onClose={onModalClose}
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={isTkdNotSelected || isLoading || !isValid}
      >
        <Form.Grid>
          <Styled.InputWrapper>
            <Controller
              name="nazwa"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  label={formatMessage({ id: AppMessages['announcement.add.name.label'] })}
                  placeholder={formatMessage({ id: AppMessages['announcement.add.name.placeholder'] })}
                  errorMessage={error?.message}
                  required
                />
              )}
            />
          </Styled.InputWrapper>
          <Select
            value={selectedServerId}
            required
            onChange={(e) => {
              setValue('tkd', '');
              clearErrors('tkd');
              setSelectedServerId(e.target.value as string);
            }}
            label={formatMessage({ id: AppMessages['announcement.add.server.label'] })}
            placeholder={formatMessage({ id: AppMessages['announcement.add.server.placeholder'] })}
            withHelperText
            options={serverOptions}
          />
          <div>
            <FormAutocompleteContainer
              formFieldName="tkd"
              queryKey={QueryKeyEnum.TERMINALS}
              queryFunction={() => fetchTerminals(selectedServerId)}
              additionalKeys={selectedServerId ? [selectedServerId] : undefined}
              noDataCallback={() => {
                setError('tkd', { message: formatMessage({ id: AppMessages['announcement.add.terminal.error'] }) });
              }}
              args={{
                sort: [GroupTerminalsPermissionsIdSortEnum.ASC],
                zaakceptowane: true,
                wyswietlacz: true,
              }}
              createOption={(terminal) => {
                return terminal.id
                  ? {
                      label: terminal.nazwa,
                      value: terminal.id,
                      id: `${terminal.numerLogicznyKontrolera}`,
                    }
                  : (terminal as unknown as Option);
              }}
              handleOptionSelect={(option) => option.value}
              renderCustomOption={(props, option, terminals) => (
                <AutocompleteOption
                  props={props}
                  key={option.id}
                  idPrefix={formatMessage({ id: AppMessages['announcement.add.terminal.id'] })}
                  id={
                    terminals
                      ? terminals.find((terminal) => terminal.id === option.value)?.numerLogicznyKontrolera || 0
                      : ''
                  }
                  label={option.label}
                />
              )}
              label={formatMessage({ id: AppMessages['announcement.add.terminal.label'] })}
              placeholder={formatMessage({ id: AppMessages['announcement.add.terminal.placeholder'] })}
              required
              withPopupSearchIcon
            />
            <Styled.ErrorMessage>{tkdErrorMessage}</Styled.ErrorMessage>
          </div>
          <Controller
            name="mod"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Select
                {...field}
                label={formatMessage({ id: AppMessages['announcement.add.mod.label'] })}
                placeholder={formatMessage({ id: AppMessages['common.list.placeholder'] })}
                options={modFilterOptions}
                required
                disabled={isLoadingMods || isTkdNotSelected || !modFilterOptions.length}
                errorMessage={error?.message}
                withHelperText
              />
            )}
          />
          <Controller
            name="tryb"
            control={control}
            render={({ field }) => (
              <MultiAutocomplete
                {...field}
                required
                withDefaultIcon
                withLimitedTags
                inputSize="big"
                limitTags={1}
                onChange={(_, option) => (option ? field.onChange(option) : field.onChange(null))}
                options={trybOptions}
                loading={false}
                label={formatMessage({ id: AppMessages['announcement.add.tryb.label'] })}
                placeholder={formatMessage({ id: AppMessages['common.list.placeholder'] })}
                renderOption={renderMultiAutocompleteOption}
                disabled={isModNotSelected}
              />
            )}
          />
          <Controller
            name="potwierdzenie"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label={formatMessage({ id: AppMessages['announcement.add.confirmation.label'] })}
                placeholder={formatMessage({ id: AppMessages['common.list.placeholder'] })}
                options={yesNoOptions}
                disabled={isModeNotSelected}
                required
              />
            )}
          />
          <Controller
            name="czasKomunikatu"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                type="number"
                label={formatMessage({ id: AppMessages['announcement.add.duration.label'] })}
                placeholder={formatMessage({ id: AppMessages['announcement.add.duration.placeholder'] })}
                errorMessage={error?.message}
                disabled={isModeNotSelected}
                required
              />
            )}
          />
          <Styled.InputWrapper>
            <Controller
              name="komunikat"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <KeywordsInput
                  {...field}
                  label={formatMessage({ id: AppMessages['announcement.add.announcement.label'] })}
                  errorMessage={error?.message}
                  keywords={announcementKeywords}
                  placeholder={formatMessage({ id: AppMessages['announcement.add.announcement.placeholder'] })}
                  required
                />
              )}
            />
          </Styled.InputWrapper>
          <Styled.FieldWrapper>
            <Controller
              control={control}
              name="ids"
              render={({ field }) => (
                <OrganizationsTreeSelect
                  {...field}
                  multiple
                  placeholder={formatMessage({ id: AppMessages['common.list.placeholder'] })}
                  label={formatMessage({ id: AppMessages['announcement.add.chooseOwners'] })}
                  extraGroupProps={{
                    options: ownersOptions,
                    label: formatMessage({ id: AppMessages['announcement.add.owner'] }),
                    onGroupSelect: setSelectedOwnersGroup,
                    renderOption: (option) => (
                      <AutocompleteOption
                        key={option.id}
                        idPrefix={formatMessage({ id: AppMessages['tableSearch.owner.number'] })}
                        id={option.kod || ''}
                        label={option.nazwa}
                      />
                    ),
                  }}
                />
              )}
            />
          </Styled.FieldWrapper>
        </Form.Grid>
      </Modal>
    </>
  );
};
