import { FilterOptionsState } from '@mui/material';
import { Option } from 'shared-ui';

import { QueryKeyEnum } from 'core/global.enum';
import { useQuery } from 'hooks/useQuery/useQuery';
import { AnnouncementsIdSortEnum } from 'context/query/queryContext/QueryContext.enum';
import { fetchAllAnnouncementsNames } from 'api/announcements/announcements';
import * as Styled from '../TableSearch.styles';

export const useSearchByAnnouncements = (optionsQuery: string, isEnabled: boolean) => {
  const { data: announcementsResponse, isInitialLoading } = useQuery(
    [QueryKeyEnum.ANNOUNCEMENTS_SEARCH, optionsQuery],
    () => fetchAllAnnouncementsNames(),
    {
      args: {
        sort: [AnnouncementsIdSortEnum.ASC],
        query: optionsQuery,
      },
      enabled: isEnabled,
    },
  );

  const announcements = announcementsResponse?.data || [];

  const createOptions = () => {
    return announcements.map((announcement) => ({
      value: announcement,
      label: announcement,
    }));
  };

  const filterOptions = (options: Option[], { inputValue }: FilterOptionsState<Option>) => {
    const standarizedInputValue = inputValue.toLowerCase();

    return options.filter(
      ({ label, value }) =>
        label.toLowerCase().includes(standarizedInputValue) || `${value}`.toLowerCase().includes(standarizedInputValue),
    );
  };

  const createOptionLabel = (optionProps: React.HTMLAttributes<HTMLLIElement>, option: Option) => {
    return (
      <li {...optionProps} key={`${option.value}-${Math.random()}`}>
        <Styled.OptionLabel sx={{ py: 0.7 }}>{option.label}</Styled.OptionLabel>
      </li>
    );
  };

  return { isLoading: isInitialLoading, createOptions, createOptionLabel, filterOptions };
};
