import { calculateTextWidth } from './calculateTextWidth';

export const splitTextByWidth = (str: string, maxWidth: number): string[] => {
  const splitWordByWidth = (word: string, maxWidth: number): string[] => {
    const parts: string[] = [];
    let currentPart = '';

    for (let i = 0; i < word.length; i++) {
      currentPart += word[i];
      const currentPartWidth = calculateTextWidth(currentPart);

      if (currentPartWidth > maxWidth) {
        parts.push(currentPart.slice(0, -1));
        currentPart = word[i];
      }
    }

    if (currentPart) {
      parts.push(currentPart);
    }

    return parts;
  };

  return str
    .split(/(\s+)/)
    .reduce((result: string[], textPart: string) => {
      const currentLine = result.pop() || '';
      const testLine = currentLine + textPart;
      const testWidth = calculateTextWidth(testLine);

      if (testWidth <= maxWidth) {
        result.push(testLine);
      } else {
        if (currentLine.trim()) {
          result.push(currentLine);
        }

        if (/\s/.test(textPart)) {
          result.push(textPart.trimStart());
        } else {
          const wordParts = splitWordByWidth(textPart.trim(), maxWidth);
          result.push(...wordParts);
        }
      }

      return result;
    }, [])
    .map((line) => line.trimEnd());
};
