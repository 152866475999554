import { formatISO } from 'date-fns';
import { Autocomplete } from 'shared-ui';

import { HistoryDatepickerWithModal } from 'reusable/historyDatepickerWithModal/HistoryDatepickerWithModal';
import { TableSearchContainer } from 'reusable/tableSearch/TableSearchContainer';
import { HistoryActionAutocomplete } from 'reusable/historyActionAutocomplete/HistoryActionAutocomplete';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { AnnouncementsHistoryQuery } from 'context/query/queryContext/QueryContext.types';
import { QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';
import { useAppSelectOptions } from 'hooks/useAppSelectOptions/useAppSelectOptions';

import { AnnouncementsHistoryFiltersProps } from './AnnouncementsHistoryFilters.types';
import * as Styled from './AnnouncementsHistoryFilters.styles';

export const AnnouncementsHistoryFilters = ({
  servers,
  executorSearchQuery,
  setExecutorSearchQuery,
  tkdSearchQuery,
  setTkdSearchQuery,
  ownerSearchQuery,
  setOwnerSearchQuery,
  nameSearchQuery,
  setNameSearchQuery,
}: AnnouncementsHistoryFiltersProps) => {
  const { formatMessage } = useLocale();

  const {
    storedQueries: { announcementsHistory: announcementsHistoryQuery },
    setQuery,
  } = useLocaleStoreQuery();

  const setAnnouncementsHistoryQuery = (query: AnnouncementsHistoryQuery) => {
    setQuery(QueryKeyLocalStorageEnum.ANNOUNCEMENTS_HISTORY, { ...announcementsHistoryQuery, ...query });
  };

  const { serverOptions, announcementModOptions, modeOptions } = useAppSelectOptions({ servers });

  return (
    <>
      <HistoryDatepickerWithModal
        dates={[
          announcementsHistoryQuery.dataOd ? new Date(announcementsHistoryQuery.dataOd) : null,
          announcementsHistoryQuery.dataDo ? new Date(announcementsHistoryQuery.dataDo) : null,
        ]}
        setDates={(dates) =>
          setAnnouncementsHistoryQuery({
            dataOd: dates[0] ? formatISO(dates[0]) : undefined,
            dataDo: dates[1] ? formatISO(dates[1]) : undefined,
          })
        }
      />

      <HistoryActionAutocomplete
        selectedAction={announcementsHistoryQuery.akcja}
        onChangeAction={(action) => setAnnouncementsHistoryQuery({ akcja: action })}
        historyType="announcements"
      />

      <Autocomplete
        onChange={(_, option) => setAnnouncementsHistoryQuery({ serwer: option || undefined, tkd: undefined })}
        value={serverOptions.find((server) => announcementsHistoryQuery.serwer?.value === server.value) || null}
        options={serverOptions}
        loadingText={formatMessage({ id: AppMessages['common.loading'] })}
        noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
        placeholder={formatMessage({ id: AppMessages['announcements.history.filters.server'] })}
        inputVariant="outlined"
        inputSize="big"
        isOptionEqualToValue={(option, value) => option.value === value.value}
        stopRotatePopupIndicator
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            <Styled.AutocompleteOption>{option.label}</Styled.AutocompleteOption>
          </li>
        )}
      />

      <TableSearchContainer
        withoutToggle
        inputPlaceholder={formatMessage({ id: AppMessages['announcements.history.filters.reader'] })}
        searchBy="terminals"
        tableSearchQuery={tkdSearchQuery}
        setTableSearchQuery={setTkdSearchQuery}
        selectedValue={announcementsHistoryQuery.tkd}
        setSelectedValue={(option) => setAnnouncementsHistoryQuery({ tkd: option || undefined })}
        inputSize="big"
        isDisabled={!announcementsHistoryQuery.serwer}
        serverId={announcementsHistoryQuery.serwer?.value as string}
      />

      <Autocomplete
        onChange={(_, option) => setAnnouncementsHistoryQuery({ mod: option?.value as string })}
        value={announcementModOptions.find((mod) => announcementsHistoryQuery.mod === mod.value) || null}
        options={announcementModOptions}
        loadingText={formatMessage({ id: AppMessages['common.loading'] })}
        noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
        placeholder={formatMessage({ id: AppMessages['announcements.history.filters.mod'] })}
        inputVariant="outlined"
        inputSize="big"
        isOptionEqualToValue={(option, value) => option.value === value.value}
        stopRotatePopupIndicator
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            <Styled.AutocompleteOption>{option.label.toLowerCase()}</Styled.AutocompleteOption>
          </li>
        )}
      />

      <Autocomplete
        onChange={(_, option) => setAnnouncementsHistoryQuery({ tryb: option?.value as string })}
        value={modeOptions.find((mode) => announcementsHistoryQuery.tryb === mode.value) || null}
        options={modeOptions}
        loadingText={formatMessage({ id: AppMessages['common.loading'] })}
        noOptionsText={formatMessage({ id: AppMessages['common.notFound'] })}
        placeholder={formatMessage({ id: AppMessages['announcements.history.filters.mode'] })}
        inputVariant="outlined"
        inputSize="big"
        isOptionEqualToValue={(option, value) => option.value === value.value}
        stopRotatePopupIndicator
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            <Styled.AutocompleteOption>{option.label.toLowerCase()}</Styled.AutocompleteOption>
          </li>
        )}
      />

      <TableSearchContainer
        withoutToggle
        inputPlaceholder={formatMessage({ id: AppMessages['announcements.history.filters.name'] })}
        searchBy="announcements"
        tableSearchQuery={nameSearchQuery}
        setTableSearchQuery={setNameSearchQuery}
        selectedValue={announcementsHistoryQuery.nazwa}
        setSelectedValue={(option) => setAnnouncementsHistoryQuery({ nazwa: option || undefined })}
        inputSize="big"
      />

      <TableSearchContainer
        withoutToggle
        inputPlaceholder={formatMessage({ id: AppMessages['announcements.history.filters.executor'] })}
        searchBy="users"
        tableSearchQuery={executorSearchQuery}
        setTableSearchQuery={setExecutorSearchQuery}
        selectedValue={announcementsHistoryQuery.query}
        setSelectedValue={(option) => setAnnouncementsHistoryQuery({ query: option || undefined })}
        inputSize="big"
      />

      <TableSearchContainer
        withoutToggle
        inputPlaceholder={formatMessage({ id: AppMessages['announcements.history.filters.owner'] })}
        searchBy="owners"
        tableSearchQuery={ownerSearchQuery}
        setTableSearchQuery={setOwnerSearchQuery}
        selectedValue={announcementsHistoryQuery.posiadacz}
        setSelectedValue={(option) =>
          setAnnouncementsHistoryQuery({ posiadacz: option ? { ...option, value: option.id as string } : undefined })
        }
        inputSize="big"
      />
    </>
  );
};
