import { InputLabel } from '@mui/material';
import styled, { css } from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const ButtonsWrapper = styled.div`
  padding: 24px 0;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
`;

export const DetailsItemWrapper = styled.div<{ $withMarginBottom?: boolean }>(
  ({ $withMarginBottom }) => css`
    display: grid;
    grid-template-columns: 0.6fr 1fr;
    margin-bottom: ${$withMarginBottom ? '24px' : 0};
  `,
);

export const DetailText = styled(InputLabel)<{ $withoutCenter?: boolean }>(
  ({ theme: { palette }, $withoutCenter }) => css`
    color: ${palette.grey[700]};
    font-size: 14px;
    padding: 4px 0px;
    align-self: ${$withoutCenter ? 'start' : 'center'};
    font-weight: 700;
    text-wrap: wrap;
  `,
);
