import { ProblemIconWithTooltip } from 'shared-ui';

import { Table } from 'ui/table/Table';
import { AppMessages } from 'i18n/messages';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AnnouncementModShortDescriptionEnum } from 'api/announcements/announcements.enum';
import { TypographyWithTooltip } from 'ui/truncatedTypographyWithTooltip/TypographyWithTooltip';

import { AnnouncementsTableRowProps } from './AnnouncementsTableRow.types';

export const AnnouncementsTableRow = ({ announcement }: AnnouncementsTableRowProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Table.RowItem>
        <TypographyWithTooltip text={announcement.nazwa} variant="body2" />
      </Table.RowItem>

      <Table.RowItem>{announcement.tkd.numerLogicznyKontrolera}</Table.RowItem>

      <Table.RowItem>{announcement.tkd.nazwa}</Table.RowItem>

      <Table.RowItem>
        <ProblemIconWithTooltip
          isError={announcement.problemTkd}
          tooltip={formatMessage({ id: AppMessages['announcements.notUpdated.info'] })}
        />
      </Table.RowItem>

      <Table.RowItem>
        {formatMessage({ id: AnnouncementModShortDescriptionEnum[announcement.mod] }).toLocaleUpperCase()}
      </Table.RowItem>

      <Table.RowCellsItem
        cells={announcement.tryb.map((item) => ({
          id: String(item),
          nazwa: item,
        }))}
        withMoreCellsNumber
        oneLineTooltipItems
      />

      <Table.RowItem>
        {announcement.pkd
          ? formatMessage({ id: AppMessages['common.yes'] }).toUpperCase()
          : formatMessage({ id: AppMessages['common.no'] }).toUpperCase()}
      </Table.RowItem>
    </>
  );
};
