import { EmptyList, useLocale } from 'shared-ui';

import { Table } from 'ui/table/Table';
import { useSelectedAnnouncement } from 'hooks/useSelectedAnnouncement/useSelectedAnnouncement';
import { AppMessages } from 'i18n/messages';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { AnnouncementsQueryKeysEnum, QueryKeyLocalStorageEnum } from 'context/query/queryContext/QueryContext.enum';

import { AnnouncementsTableProps } from './AnnouncementsTable.types';
import { AnnouncementsTableHeader } from './announcementsTableHeader/AnnouncementsTableHeader';
import { AnnouncementsTableRow } from './announcementsTableRow/AnnouncementsTableRow';

export const AnnouncementsTable = ({
  announcementsResponse,
  setCheckedAnnouncements,
  checkedAnnouncements,
  announcementsTableSearchQuery,
}: AnnouncementsTableProps) => {
  const { formatMessage } = useLocale();
  const { selectedAnnouncement, setSelectedAnnouncement } = useSelectedAnnouncement();
  const {
    storedQueries: { [QueryKeyLocalStorageEnum.ANNOUNCEMENTS]: announcementsQuery },
  } = useLocaleStoreQuery();

  const areSomeFiltersSelected = Object.entries(announcementsQuery).some(
    ([key, value]) =>
      ![AnnouncementsQueryKeysEnum.SORT, AnnouncementsQueryKeysEnum.LIMIT, AnnouncementsQueryKeysEnum.OFFSET].includes(
        key as AnnouncementsQueryKeysEnum,
      ) && !!value,
  );

  return (
    <Table
      gridTemplateColumns="20% 0.7fr minmax(180px, 1fr) 1fr 1fr 1fr 1fr"
      data={announcementsResponse?.data || []}
      checkboxSelection={{
        checked: checkedAnnouncements,
        toggleChecked: setCheckedAnnouncements,
        withoutCheckedElementsBar: true,
      }}
      nothingToShowText={
        areSomeFiltersSelected || !!announcementsTableSearchQuery ? (
          <EmptyList
            iconType="loop"
            mainText={formatMessage({ id: AppMessages['announcements.noResults.mainText'] })}
            helperText={formatMessage({ id: AppMessages['announcements.noResults.helperText'] })}
          />
        ) : (
          formatMessage({ id: AppMessages['announcements.noResults.nothingToShowText'] })
        )
      }
      renderHeader={() => <AnnouncementsTableHeader />}
      renderRow={(announcement) => <AnnouncementsTableRow announcement={announcement} />}
      activeRowId={selectedAnnouncement?.id}
      onClickRow={(announcement) => {
        setSelectedAnnouncement(selectedAnnouncement?.id === announcement.id ? undefined : announcement);
      }}
      customTableHeight={() => undefined}
    />
  );
};
