import { useMeasure } from 'react-use';
import { Tooltip } from 'shared-ui';
import { Typography } from '@mui/material';

import { calculateTextWidth } from 'ui/keywordsInput/utils/calculateTextWidth';

import { TypographyWithTooltipProps } from './TypographyWithTooltip.types';

export const TypographyWithTooltip = ({ text, ...props }: TypographyWithTooltipProps) => {
  const [textRef, { width }] = useMeasure<HTMLDivElement>();

  return (
    <Tooltip placement="bottom-start" disableHoverListener={calculateTextWidth(`${text}`) < width} title={text}>
      <Typography {...props} ref={textRef} noWrap>
        {text}
      </Typography>
    </Tooltip>
  );
};
