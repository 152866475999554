import { useEffect, useState } from 'react';
import { APIError, ClientError, useErrorHandler, useQuery, useQueryClient } from 'shared-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

import { QueryKeyEnum } from 'core/global.enum';
import { PaginatedResponse } from 'core/global.types';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';
import { Server } from 'api/servers/servers.types';
import { addAnnouncement, fetchAnnouncementModes, fetchAnnouncementMods } from 'api/announcements/announcements';
import { AddAnnouncementBody } from 'api/announcements/announcements.types';
import { MultiTreeSelectGroupTypeEnum } from 'ui/organizationsTreeSelect/treeMultiSelect/groupedTreeMultiSelectBody/GroupedTreeMultiSelectBody.enum';
import { fetchOwners } from 'api/owners/owners';
import { OwnerRegistrationNumberSortEnum } from 'context/query/queryContext/QueryContext.enum';

import { useAddAnnouncementValidation } from './AddAnnouncement.validation';
import { AddAnnouncementFormBody } from './AddAnnouncement.types';
import { AddAnnouncement } from './AddAnnouncement';

export const AddAnnouncementContainer = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const { schema } = useAddAnnouncementValidation();
  const [selectedOwnersGroup, setSelectedOwnersGroup] = useState<MultiTreeSelectGroupTypeEnum>(
    MultiTreeSelectGroupTypeEnum.EXTRA,
  );
  const { handleError } = useErrorHandler();
  const [selectedServerId, setSelectedServerId] = useState('');

  const servers = queryClient.getQueryData<PaginatedResponse<Server>>([QueryKeyEnum.SERVERS])?.data || [];

  useEffect(() => {
    servers.length && setSelectedServerId(servers[0].id);
  }, [servers]);

  const methods = useForm<AddAnnouncementFormBody>({
    defaultValues: {
      tkd: '',
      komunikat: '',
      tryb: [],
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });
  const selectedTkd = methods.watch('tkd');

  const { mutate: onAddAnnouncement, isLoading } = useMutation(addAnnouncement, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.ANNOUNCEMENTS]);
      queryClient.invalidateQueries([QueryKeyEnum.ANNOUNCEMENTS_SEARCH]);

      setTimeout(() => {
        queryClient.invalidateQueries([QueryKeyEnum.ANNOUNCEMENTS]);
      }, 5000);

      toast.dark(formatMessage({ id: AppMessages['announcement.add.success'] }));

      onModalClose();
    },
    onError: (error: ClientError<APIError>) => {
      const errorFields = error.response?.data.fields;

      const isAnnouncementExistsError = errorFields?.find(({ type, field }) => type === 'exists' && field === 'nazwa');

      if (isAnnouncementExistsError) {
        return methods.setError('nazwa', {
          message: formatMessage({ id: AppMessages['announcement.add.name.validation'] }),
        });
      }

      handleError({ error, omitAPIErrorMessage: true });
    },
  });

  const { data: ownersResponse } = useQuery([QueryKeyEnum.OWNERS_SEARCH], fetchOwners, {
    args: {
      offset: 0,
      limit: 100,
      sort: [OwnerRegistrationNumberSortEnum.ASC],
    },
  });
  const owners = ownersResponse?.data || [];

  const { data: modsResponse, isLoading: isLoadingMods } = useQuery(
    [QueryKeyEnum.ANNOUNCEMENT_MODS, selectedTkd],
    () => fetchAnnouncementMods(selectedTkd),
    {
      enabled: !!selectedTkd,
      onSuccess: (response) => {
        if (!response.mods?.length)
          methods.setError('mod', { message: formatMessage({ id: AppMessages['announcement.add.mod.validation'] }) });
      },
    },
  );
  const mods = modsResponse?.mods || [];

  const selectedMod = methods.watch('mod');
  const { data: modesResponse } = useQuery(
    [QueryKeyEnum.ANNOUNCEMENT_MODES, selectedTkd, selectedMod],
    () => fetchAnnouncementModes(selectedTkd),
    {
      args: { mod: selectedMod },
      enabled: !!selectedMod && !!selectedTkd,
      onSuccess: (response) => {
        if (!response?.length)
          methods.setError('mod', { message: formatMessage({ id: AppMessages['announcement.add.terminal.error'] }) });
      },
    },
  );
  const modes = modesResponse || [];

  const onSubmit = ({ ids, ...rest }: AddAnnouncementFormBody) => {
    const selectedGroupKey = selectedOwnersGroup === MultiTreeSelectGroupTypeEnum.EXTRA ? 'idPkds' : 'idKomorek';

    onAddAnnouncement({
      ...rest,
      [selectedGroupKey]: typeof ids === 'string' ? [ids] : ids?.map(({ id }) => id) || [],
      tryb: rest.tryb?.map(({ value }) => value as number) || [],
      czasKomunikatu: rest.czasKomunikatu ? Number(rest.czasKomunikatu) : 0,
      potwierdzenie: rest?.potwierdzenie === 'true',
    } as AddAnnouncementBody);
  };

  useEffect(() => {
    methods.reset();
  }, [isModalOpen]);

  useEffect(() => {
    methods.clearErrors('mod');
  }, [selectedTkd]);

  return (
    <FormProvider {...methods}>
      <AddAnnouncement
        selectedServerId={selectedServerId}
        setSelectedServerId={setSelectedServerId}
        onSubmit={onSubmit}
        isModalOpen={isModalOpen}
        onModalOpen={onModalOpen}
        onModalClose={onModalClose}
        isLoading={isLoading}
        servers={servers}
        mods={mods}
        modes={modes}
        setSelectedOwnersGroup={setSelectedOwnersGroup}
        owners={owners}
        isLoadingMods={isLoadingMods}
      />
    </FormProvider>
  );
};
