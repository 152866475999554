import {
  QueryKeyLocalStorageEnum,
  CardNumberSortEnum,
  OwnerIdSortEnum,
  OwnerPermissionsIdSortEnum,
  OwnerGroupIdSortEnum,
  PositionNameSortEnum,
  EventExportFormatNameSortEnum,
  GroupsIdSortEnum,
  GroupTerminalsPermissionsIdSortEnum,
  EventDateSortEnum,
  TerminalIdSortEnum,
  OwnersQueryKeysEnum,
  EventsQueryKeysEnum,
  GlcTerminalsIdSortEnum,
  GroupGlcPermissionsIdSortEnum,
  GlcNumberSortEnum,
  TerminalWithDisplayIdSortEnum,
  InstantControlIdSortEnum,
  InstantControlTerminalsIdSortEnum,
  RandomControlIdSortEnum,
  RandomControlTerminalsIdSortEnum,
  WantedIdSortEnum,
  WantedTerminalsIdSortEnum,
  AnnouncementsNameSortEnum,
} from '../queryContext/QueryContext.enum';
import { QueryState } from '../queryContext/QueryContext.types';

export const DEFAULT_QUERY_STATE: QueryState = {
  [QueryKeyLocalStorageEnum.CARDS]: { sort: [CardNumberSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.ARCHIVED_CARDS]: { sort: [CardNumberSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.TERMINALS]: { sort: [TerminalIdSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.ACTIVE_CARD_HISTORY]: {},
  [QueryKeyLocalStorageEnum.ARCHIVED_CARD_HISTORY]: {},
  [QueryKeyLocalStorageEnum.EVENTS_HISTORY]: {
    sort: [EventDateSortEnum.DESC],
    [EventsQueryKeysEnum.DATA_OD]: undefined,
    [EventsQueryKeysEnum.DATA_DO]: undefined,
    [EventsQueryKeysEnum.ZDARZENIE]: undefined,
    [EventsQueryKeysEnum.MOD]: undefined,
    [EventsQueryKeysEnum.TRYB]: undefined,
    [EventsQueryKeysEnum.RCP]: undefined,
    [EventsQueryKeysEnum.SERVER]: undefined,
  },
  [QueryKeyLocalStorageEnum.OWNERS]: { sort: [OwnerIdSortEnum.ASC], [OwnersQueryKeysEnum.STANOWISKO]: [] },
  [QueryKeyLocalStorageEnum.ARCHIVED_OWNERS]: { sort: [OwnerIdSortEnum.ASC], [OwnersQueryKeysEnum.STANOWISKO]: [] },
  [QueryKeyLocalStorageEnum.ACTIVE_OWNER_HISTORY]: {},
  [QueryKeyLocalStorageEnum.ACTIVE_OWNER_PERMISSIONS_EFFECTIVE]: { sort: [OwnerPermissionsIdSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.ACTIVE_OWNER_PERMISSIONS_INDIVIDUAL]: { sort: [OwnerPermissionsIdSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.ACTIVE_OWNER_GROUPS]: { sort: [OwnerGroupIdSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.ARCHIVED_OWNER_HISTORY]: {},
  [QueryKeyLocalStorageEnum.ARCHIVED_OWNER_PERMISSIONS_EFFECTIVE]: { sort: [OwnerPermissionsIdSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.ARCHIVED_OWNER_PERMISSIONS_INDIVIDUAL]: { sort: [OwnerPermissionsIdSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.ARCHIVED_OWNER_GROUPS]: { sort: [OwnerGroupIdSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.POSITIONS]: { sort: [PositionNameSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.EVENTS_EXPORT_FORMAT]: { sort: [EventExportFormatNameSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.GROUPS]: { sort: [GroupsIdSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.GROUP_HISTORY]: {},
  [QueryKeyLocalStorageEnum.GROUP_PERMISSIONS_HISTORY]: {},
  [QueryKeyLocalStorageEnum.ARCHIVED_GROUPS]: { sort: [GroupsIdSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.GROUP_TERMINALS_PERMISSIONS]: { sort: [GroupTerminalsPermissionsIdSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.GROUP_GLC_PERMISSIONS]: { sort: [GroupGlcPermissionsIdSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.GROUP_ASSIGNED_OWNERS]: { sort: [OwnerIdSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.ACTIVE_GLC]: { sort: [GlcNumberSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.ARCHIVED_GLC]: { sort: [GlcNumberSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.GLC_TERMINALS]: { sort: [GlcTerminalsIdSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.GLC_HISTORY]: {},
  [QueryKeyLocalStorageEnum.USERS]: {},
  [QueryKeyLocalStorageEnum.SELECTED_SEARCH_OPTIONS]: {},
  [QueryKeyLocalStorageEnum.OWNER_EFFECTIVE_PERMISSIONS_HISTORY]: {},
  [QueryKeyLocalStorageEnum.TERMINALS_WITH_DISPLAY]: { sort: [TerminalWithDisplayIdSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.CONTROL_HISTORY]: {},
  [QueryKeyLocalStorageEnum.INSTANT_CONTROL]: { sort: [InstantControlIdSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.INSTANT_CONTROL_TERMINALS]: { sort: [InstantControlTerminalsIdSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.RANDOM_CONTROL]: { sort: [RandomControlIdSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.RANDOM_CONTROL_TERMINALS]: { sort: [RandomControlTerminalsIdSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.WANTED]: { sort: [WantedIdSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.WANTED_HISTORY]: {},
  [QueryKeyLocalStorageEnum.WANTED_TERMINALS]: { sort: [WantedTerminalsIdSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.ANNOUNCEMENTS]: { sort: [AnnouncementsNameSortEnum.ASC] },
  [QueryKeyLocalStorageEnum.ANNOUNCEMENTS_HISTORY]: {},
  [QueryKeyLocalStorageEnum.ANNOUNCEMENT_ASSIGNED_OWNERS]: { sort: [OwnerIdSortEnum.ASC] },
};
