import { Typography } from '@mui/material';

import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { HistoryList } from 'ui/historyList/HistoryList';
import { useLocaleStoreQuery } from 'hooks/useLocaleStoreQuery/useLocaleStoreQuery';
import { HistoryActionEnum } from 'core/global.enum';

import { AnnouncementsHistoryFilters } from './controlHistoryFilters/AnnouncementslHistoryFilters';
import { AnnouncementsHistoryProps } from './AnnouncementsHistory.types';

export const AnnouncementsHistory = ({
  announcementsHistoryResponse,
  isLoading,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  servers,
  executorSearchQuery,
  setExecutorSearchQuery,
  tkdSearchQuery,
  setTkdSearchQuery,
  ownerSearchQuery,
  setOwnerSearchQuery,
  nameSearchQuery,
  setNameSearchQuery,
  onClearAnnouncementsHistoryQuery,
}: AnnouncementsHistoryProps) => {
  const { formatMessage } = useLocale();

  const {
    storedQueries: { announcementsHistory: announcementsHistoryQuery },
  } = useLocaleStoreQuery();

  const isClearFiltersButtonVisible =
    Object.values(announcementsHistoryQuery).some((query) => query) ||
    !!executorSearchQuery ||
    !!tkdSearchQuery ||
    !!ownerSearchQuery ||
    !!nameSearchQuery;

  return (
    <HistoryList
      data={announcementsHistoryResponse?.data || []}
      isLoading={isLoading}
      filters={{
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        isClearButtonVisible: isClearFiltersButtonVisible,
        onClearFilters: onClearAnnouncementsHistoryQuery,
        render: () => (
          <AnnouncementsHistoryFilters
            servers={servers}
            tkdSearchQuery={tkdSearchQuery}
            executorSearchQuery={executorSearchQuery}
            ownerSearchQuery={ownerSearchQuery}
            setExecutorSearchQuery={setExecutorSearchQuery}
            setOwnerSearchQuery={setOwnerSearchQuery}
            setTkdSearchQuery={setTkdSearchQuery}
            nameSearchQuery={nameSearchQuery}
            setNameSearchQuery={setNameSearchQuery}
          />
        ),
      }}
      pagination={{
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        count: announcementsHistoryResponse?.count || 0,
      }}
      renderHistoryItemBody={(history) => (
        <>
          {history.wykonawca && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['announcements.history.label.executor'] })}
              value={`${history.wykonawca.imie} ${history.wykonawca.nazwisko}`}
            />
          )}

          {history.nazwa &&
            (typeof history.nazwa === 'string' ? (
              <HistoryList.ActionBodyItem
                title={formatMessage({ id: AppMessages['announcements.history.label.name'] })}
                value={history.nazwa}
              />
            ) : (
              <HistoryList.ActionModifiedFieldBodyItem
                title={formatMessage({ id: AppMessages['announcements.history.label.name'] })}
                oldValue={history.nazwa.old}
                newValue={history.nazwa.new}
              />
            ))}

          {history.tkds && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['announcements.history.label.server'] })}
              value={history.tkds[0].server.nazwa}
            />
          )}

          {history.tkds && (
            <HistoryList.ActionListdBodyItem
              title={formatMessage(
                { id: AppMessages['announcements.history.label.readers'] },
                { count: history.tkds.length },
              )}
              items={history.tkds}
              shouldScrollToTop={!!announcementsHistoryQuery.tkd && !isLoading}
              renderItemBody={(tkd) => (
                <HistoryList.RenderedActionListdBodyItem
                  numberPrefix={formatMessage({ id: AppMessages['common.id'] })}
                  number={tkd.numer}
                  value={tkd.nazwa}
                />
              )}
            />
          )}

          {history.mod && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['announcements.history.label.mod'] })}
              value={history.mod}
            />
          )}

          {history.tryb && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['announcements.history.label.mode'] })}
              value={history.tryb.join(', ')}
            />
          )}

          {history.potwierdzenie !== undefined &&
            (typeof history.potwierdzenie === 'boolean' ? (
              <HistoryList.ActionBodyItem
                title={formatMessage({ id: AppMessages['announcements.history.label.confirmation'] })}
                value={formatMessage({ id: AppMessages[history.potwierdzenie ? 'common.yes' : 'common.no'] })}
              />
            ) : (
              <HistoryList.ActionModifiedFieldBodyItem
                title={formatMessage({ id: AppMessages['announcements.history.label.confirmation'] })}
                oldValue={formatMessage({ id: AppMessages[history.potwierdzenie.old ? 'common.yes' : 'common.no'] })}
                newValue={formatMessage({ id: AppMessages[history.potwierdzenie.new ? 'common.yes' : 'common.no'] })}
              />
            ))}

          {history.czasKomunikatu &&
            (typeof history.czasKomunikatu === 'number' ? (
              <HistoryList.ActionBodyItem
                title={formatMessage({ id: AppMessages['announcements.history.label.announcementTime'] })}
                value={`${history.czasKomunikatu}`}
              />
            ) : (
              <HistoryList.ActionModifiedFieldBodyItem
                title={formatMessage({ id: AppMessages['announcements.history.label.announcementTime'] })}
                oldValue={history.czasKomunikatu.old}
                newValue={history.czasKomunikatu.new}
              />
            ))}

          {history.komunikat &&
            (typeof history.komunikat === 'string' ? (
              <HistoryList.ActionListdBodyItem
                title={formatMessage({ id: AppMessages['announcements.history.label.announcement'] })}
                items={[{ id: '1', title: history.komunikat }]}
                renderItemBody={(item) => <Typography sx={{ fontWeight: 700 }}>{item.title}</Typography>}
              />
            ) : (
              <HistoryList.ActionModifiedFieldBodyItem
                title={formatMessage({ id: AppMessages['announcements.history.label.announcement'] })}
                oldValue={history.komunikat.old}
                newValue={history.komunikat.new}
              />
            ))}

          {history.posiadacze && (
            <HistoryList.ActionListdBodyItem
              title={formatMessage(
                {
                  id: AppMessages[
                    history.akcja === HistoryActionEnum.ZEDYTOWANO
                      ? 'announcements.history.label.addedOwners'
                      : 'announcements.history.label.owners'
                  ],
                },
                { count: history.posiadacze.length },
              )}
              items={history.posiadacze}
              shouldScrollToTop={!!announcementsHistoryQuery.posiadacz && !isLoading}
              renderItemBody={(owner) => (
                <HistoryList.RenderedActionListdBodyItem
                  numberPrefix={formatMessage({ id: AppMessages['common.registrationNumber'] })}
                  number={owner.nrEwidencyjny}
                  value={`${owner.imie} ${owner.nazwisko}`}
                />
              )}
            />
          )}

          {history.usunieciPosiadacze && (
            <HistoryList.ActionListdBodyItem
              title={formatMessage(
                { id: AppMessages['announcements.history.label.removedOwners'] },
                { count: history.usunieciPosiadacze.length },
              )}
              items={history.usunieciPosiadacze}
              shouldScrollToTop={!!announcementsHistoryQuery.posiadacz && !isLoading}
              renderItemBody={(owner) => (
                <HistoryList.RenderedActionListdBodyItem
                  numberPrefix={formatMessage({ id: AppMessages['common.registrationNumber'] })}
                  number={owner.nrEwidencyjny}
                  value={`${owner.imie} ${owner.nazwisko}`}
                />
              )}
            />
          )}

          {history.powod && (
            <HistoryList.ActionBodyItem
              title={formatMessage({ id: AppMessages['announcements.history.label.reason'] })}
              value={history.powod}
            />
          )}
        </>
      )}
    />
  );
};
