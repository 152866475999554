import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { Table } from 'ui/table/Table';
import { TypographyWithTooltip } from 'ui/truncatedTypographyWithTooltip/TypographyWithTooltip';

import { GlcTableRowProps } from './GlcTableRow.types';

export const GlcTableRow = ({ glc }: GlcTableRowProps) => {
  const { formatMessage } = useLocale();

  return (
    <>
      <Table.RowItem>{glc.idGlc}</Table.RowItem>

      <Table.RowItem>
        <TypographyWithTooltip text={glc.nazwa} variant="body2" />
      </Table.RowItem>

      <Table.RowItem>
        {glc.uprGrupowe
          ? formatMessage({ id: AppMessages['common.yes'] }).toUpperCase()
          : formatMessage({ id: AppMessages['common.no'] }).toUpperCase()}
      </Table.RowItem>

      <Table.RowItem>
        {!!glc.tkds?.length
          ? formatMessage({ id: AppMessages['common.yes'] }).toUpperCase()
          : formatMessage({ id: AppMessages['common.no'] }).toUpperCase()}
      </Table.RowItem>
    </>
  );
};
