import { useQueryClient } from 'shared-ui';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';

import { QueryKeyEnum } from 'core/global.enum';
import { useLocale } from 'hooks/useLocale/useLocale';
import { AppMessages } from 'i18n/messages';
import { useMutation } from 'hooks/useMutation/useMutation';
import { useModalState } from 'hooks/useModalState/useModalState';
import { modifyAnnouncements } from 'api/announcements/announcements';
import { ModifyAnnouncementsBody } from 'api/announcements/announcements.types';
import { useSelectedAnnouncement } from 'hooks/useSelectedAnnouncement/useSelectedAnnouncement';

import { ModifyMultiAnnouncements } from './ModifyMultiAnnouncements';
import {
  ModifyMultiAnnouncementsContainerProps,
  ModifyMultiAnnouncementsFormBody,
} from './ModifyMultiAnnouncements.types';
import { useModifyMultiAnnouncementsValidation } from './ModifyMultiAnnouncements.validation';

export const ModifyMultiAnnouncementsContainer = ({
  checkedAnnouncements,
  setCheckedAnnouncements,
}: ModifyMultiAnnouncementsContainerProps) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useLocale();
  const { isModalOpen, onModalOpen, onModalClose } = useModalState();
  const { selectedAnnouncement, setSelectedAnnouncement } = useSelectedAnnouncement();

  const { schema } = useModifyMultiAnnouncementsValidation();

  const methods = useForm<ModifyMultiAnnouncementsFormBody>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      czasKomunikatu: '',
      potwierdzenie: null,
      komunikat: '',
    },
  });

  const { mutate: onModifyAnnouncements, isLoading } = useMutation(modifyAnnouncements, {
    onSuccess: (_, body) => {
      if (selectedAnnouncement && checkedAnnouncements.some(({ id }) => id === selectedAnnouncement?.id)) {
        setSelectedAnnouncement({ ...selectedAnnouncement, ...body });
      }

      queryClient.invalidateQueries([QueryKeyEnum.ANNOUNCEMENTS]);
      toast.dark(formatMessage({ id: AppMessages['announcements.multiAction.modify.successMessage'] }));
      setCheckedAnnouncements([]);
      onModalClose();

      setTimeout(() => {
        queryClient.invalidateQueries([QueryKeyEnum.ANNOUNCEMENTS]);
      }, 5000);
    },
  });

  const onSubmit = ({ czasKomunikatu, komunikat, potwierdzenie }: ModifyMultiAnnouncementsFormBody) => {
    onModifyAnnouncements({
      idKomunikatow: checkedAnnouncements.map(({ id }) => id),
      ...(potwierdzenie && { potwierdzenie: potwierdzenie === 'true' }),
      ...(czasKomunikatu && { czasKomunikatu }),
      ...(komunikat && { komunikat }),
    } as unknown as ModifyAnnouncementsBody);
  };

  useEffect(() => {
    methods.reset();
  }, [isModalOpen]);

  return (
    <FormProvider {...methods}>
      <ModifyMultiAnnouncements
        checkedAnnouncements={checkedAnnouncements}
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
};
